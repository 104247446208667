import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';
import { EntrySubtype } from 'Models/EntryModels';

export interface IEntrySubtypeSelectProps {
	/** The array of data that will be shown as dropdown items */
	data: EntrySubtype[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const EntrySubtypeSelect = (props: IEntrySubtypeSelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Entry Type'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem value="0">- Select -</MenuItem>
			{props.data
				.sort((a, b) => a.fullName.localeCompare(b.fullName))
				.map(a => {
					return (
						<MenuItem key={`agency-select-${a.id}`} value={a.id}>
							{a.fullName}
						</MenuItem>
					);
				})}
		</Field>
	);
};
