import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { Team } from 'Models/TeamModels';

export const useTeamsEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('teams');

	const Get = () =>
		ep.Get<Team[]>().catch(() => {
			useError('Error getting teams');
			return [] as Team[];
		});

	return { Get, IsLoading: ep.IsLoading };
};
