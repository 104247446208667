import { FormControl, LinearProgress } from '@material-ui/core';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { useAgenciesEndpoint, useEntriesEndpoint } from 'Endpoints';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Agency } from 'Models/AgencyModels';
import { AddEntry, EntrySubtype, EntryTypes } from 'Models/EntryModels';
import React, { useEffect, useState } from 'react';
import { AgencySelect, EntrySubtypeSelect, FileUpload, GlobalDatePicker } from 'Common/Forms/Fields';
import { AddEntrySchema } from './ValidationSchemas/EntrySchemas';
import { EnhancedButton } from 'Common/Elements';
import { useUsersEndpoint } from 'Endpoints';
import { UserDetails } from 'Models/UserModels';

export const AddEntryForm = () => {
	const ep = useEntriesEndpoint();
	const userEp = useUsersEndpoint();
	const agencyEp = useAgenciesEndpoint();

	const [entrySubtypes, setEntrySubtypes] = useState<EntrySubtype[]>([]);
	const [agencies, setAgencies] = useState<Agency[]>([]);
	const [user, setUser] = useState<UserDetails | undefined | null>(null);

	const nameof = useNameof<AddEntry>();

	useEffect(() => {
		ep.GetSubtypes().then(r => setEntrySubtypes(r));
		agencyEp.Get().then(r => setAgencies(r));
		userEp.GetDetails().then(r => r && setUser(r));
	}, []);

	const OccurredOnLabel = (entryTypeId: number) => {
		switch (entryTypeId) {
			case EntryTypes.AccomplishmentAward:
				return 'Date Received';
			case EntryTypes.PublicationReport:
				return 'Date Published';
			case EntryTypes.GrantExternalFunding:
				return 'Date Awarded';
			case EntryTypes.UpcomingEvent:
				return 'Will Occur On';
			default:
				return 'Occurred On';
		}
	};

	if (userEp.IsLoading || user === null) {
		return <LinearProgress />;
	}

	return (
		<Formik
			initialValues={new AddEntry(user?.agrilifeAgencyId)}
			validationSchema={AddEntrySchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				ep.Add(values)
					.then(r => r && resetForm())
					.finally(() => setSubmitting(false));
			}}
		>
			{formprops => (
				<Form
					onBlur={() =>
						formprops.setFieldValue(
							nameof('entryTypeId'),
							entrySubtypes.find(s => s.id === formprops.values.entrySubtypeId)?.entryTypeId || 0
						)
					}
				>
					<FormControl fullWidth={true}>
						<EntrySubtypeSelect data={entrySubtypes} name={nameof('entrySubtypeId')} />
					</FormControl>
					<FormControl fullWidth={true}>
						<Field
							name={nameof('description')}
							type="text"
							label="Citation"
							component={TextField}
							multiline={true}
							helperText={
								entrySubtypes.find(t => t.id === formprops.values.entrySubtypeId)?.example || ''
							}
						/>
					</FormControl>
					<FormControl fullWidth={true}>
						<Field
							component={GlobalDatePicker}
							name={nameof('occurredOn')}
							label={OccurredOnLabel(formprops.values.entryTypeId)}
						/>
					</FormControl>
					<FormControl fullWidth={true}>
						<AgencySelect data={agencies} name={nameof('agrilifeAgencyId')} />
					</FormControl>
					{formprops.values.entryTypeId === EntryTypes.GrantExternalFunding && (
						<>
							<FormControl fullWidth={true}>
								<Field
									name={nameof('grantSponsor')}
									type="text"
									label="Grant Sponsor"
									component={TextField}
								/>
							</FormControl>
							<FormControl fullWidth={true}>
								<Field
									name={nameof('grantAmount')}
									type="number"
									label="Grant Amount"
									component={TextField}
								/>
							</FormControl>
						</>
					)}
					{[EntryTypes.Presentation, EntryTypes.Meeting].includes(formprops.values.entryTypeId) && (
						<>
							<FormControl fullWidth={true}>
								<Field name={nameof('meetingName')} type="text" label="Meeting" component={TextField} />
							</FormControl>
							<FormControl fullWidth={true}>
								<Field
									name={nameof('numberInAudience')}
									type="number"
									label="Audience Size"
									component={TextField}
								/>
							</FormControl>
						</>
					)}
					<Field name={nameof('files')} label="Files" component={FileUpload} />
					<FormControl fullWidth={true}>
						{formprops.isSubmitting && <LinearProgress />}
						<EnhancedButton
							submit={true}
							isSubmitting={formprops.isSubmitting}
							action="add"
							label="Entry"
						/>
					</FormControl>
					{/* {<pre>{JSON.stringify(formprops, null, 2)}</pre>} */}
				</Form>
			)}
		</Formik>
	);
};
