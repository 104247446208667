import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';
import { Team } from 'Models/TeamModels';

export interface ITeamSelectProps {
	/** The array of teams that will be shown as dropdown items */
	data: Team[];
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const TeamSelect = (props: ITeamSelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Team'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem value="">- Select -</MenuItem>
			{props.data
				.sort((a, b) => a.name.localeCompare(b.name))
				.map(a => {
					return (
						<MenuItem key={`team-select-${a.id}`} value={a.id}>
							{a.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
