import React, { useCallback } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Avatar, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import DocumentIcon from '@material-ui/icons/Description';
import { FieldProps } from 'formik';

export type IFileUploadProps = FieldProps<File[]>

const useCardStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		'&:hover': {
			'& $button': {
				display: 'block',
			},
		},
	},
	button: {
		'& > *': {
			margin: theme.spacing(1),
		},
		backgroundColor: theme.palette.error.main,
		borderWidth: 1,
		borderRadius: 30,
		position: 'absolute',
		top: -4,
		right: -4,
		display: 'none',
		color: 'white',
		cursor: 'pointer',
	},
	docInfo: {
		backgroundColor: theme.palette.background.default,
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
}));

const FileCard = (props: { file: File; onRemove: (file: File) => void }) => {
	const classes = useCardStyles();

	return (
		<div className={classes.root}>
			<Avatar onClick={() => props.onRemove(props.file)} className={classes.button}>
				<DeleteIcon />
			</Avatar>
			{props.file.type.startsWith('image') ? (
				<img src={URL.createObjectURL(props.file)} style={{ maxWidth: '100%' }} />
			) : (
				<Paper elevation={0} className={classes.docInfo}>
					<span>
						<DocumentIcon />
					</span>
					<div>{props.file.name}</div>
				</Paper>
			)}
		</div>
	);
};

const useStyles = makeStyles(theme => ({
	listItem: {
		padding: 0,
	},
	right: {
		right: '0px !important',
	},
	alignRight: {
		paddingTop: '5px',
		textAlign: 'right',
	},
	dropzoneRoot: {
		border: '#ccc 4px dashed',
		borderRadius: 5,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		textAlign: 'center',
		minHeight: 200,
		minWidth: 400,
	},
	redBorder: {
		borderColor: theme.palette.error.main,
	},
	greenBorder: {
		borderColor: theme.palette.success.main,
	},
	padActions: {
		paddingRight: '25px',
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	uploadIcon: {
		fontSize: '2rem',
	},
	delete: { color: '#FF0000' },
}));

export const FileUpload = (props: IFileUploadProps) => {
	const { field, form } = props;
	const { name } = field;

	const classes = useStyles();

	const dropzoneAccept = [
		'image/jpeg',
		'image/png',
		'image/gif',
		'text/plain',
		'text/csv',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/zip',
		'application/x-zip-compressed',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/vnd.ms-powerpoint',
	];

	const onFileDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
		form.setFieldValue(name, [...field.value, ...acceptedFiles]);
		if (fileRejections.length) {
			console.log('rejected files', fileRejections);
		}
	}, []);

	const isSizeExceeded = () => {
		/* about 30MB (28.6MiB) https://github.com/aspnet/Announcements/issues/267 */
		return (
			field.value
				.map(f => f.size)
				.reduce((accum: number, current: number) => {
					return accum + current;
				}, 0) > 29989273
		);
	};

	const handleFileRemove = (file: File) => {
		form.setFieldValue(
			name,
			field.value.filter(f => f.name !== file.name)
		);
	};

	return (
		<>
			<Dropzone onDrop={onFileDrop} accept={dropzoneAccept}>
				{({ getRootProps, getInputProps, isDragReject, isDragAccept }) => (
					<div
						{...getRootProps({
							className: classNames(
								classes.dropzoneRoot,
								{ [classes.redBorder]: isDragReject },
								{ [classes.greenBorder]: isDragAccept }
							),
						})}
					>
						{field.value.length ? (
							<Grid container={true} spacing={1}>
								{field.value.map((f, i) => {
									return (
										<Grid item={true} md={3} sm={6} key={i}>
											<FileCard file={f} onRemove={handleFileRemove} />
										</Grid>
									);
								})}
							</Grid>
						) : (
							<>
								<input {...getInputProps()} />
								<Typography variant="h6">Drag and drop files here</Typography>
								<Typography variant="h6" color="textSecondary">
									(or click to select files)
								</Typography>
								<CloudUploadIcon htmlColor="#00c0ef" className={classes.uploadIcon} />
							</>
						)}
					</div>
				)}
			</Dropzone>
			{isSizeExceeded() && <span>file size exceeded</span>}
		</>
	);
};
