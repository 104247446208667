import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { AddEntry, EditEntry, EntryDetails, EntrySubtype, EntryType, Export } from 'Models/EntryModels';

export const useEntriesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('entries');

	const Get = (id: number) =>
		ep.Get<EntryDetails>(`${id}`).catch(() => {
			useError('Error getting entry details');
			return undefined;
		});

	const Add = (dto: AddEntry) =>
		ep
			.Post<number>(dto.toFormData())
			.then(r => {
				useSuccess('Entry added');
				return r;
			})
			.catch(() => {
				useError('Error adding entry');
				return undefined;
			});

	const GetTypes = () =>
		ep.Get<EntryType[]>('types').catch(() => {
			useError('Error getting entry types');
			return [] as EntryType[];
		});

	const GetSubtypes = () =>
		ep.Get<EntrySubtype[]>('subtypes').catch(() => {
			useError('Error getting entry sub-types');
			return [] as EntrySubtype[];
		});

	const GetExport = () =>
		ep.Get<Export[]>('export').catch(() => {
			useError('Error getting report');
			return [] as Export[];
		});

	const Edit = (dto: EditEntry) =>
		ep
			.Put<number>(dto.id, dto)
			.then(r => {
				useSuccess('Entry saved');
				return r;
			})
			.catch(() => {
				useError('Error saving entry');
				return undefined;
			});
	return { Add, Edit, Get, GetTypes, GetSubtypes, GetExport, IsLoading: ep.IsLoading };
};
