import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { UserDetails } from 'Models/UserModels';

export const useUsersEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('users');

	const GetDetails = () =>
		ep.Get<UserDetails>().catch(() => {
			useError('Error getting user details');
			return undefined;
		});

	const Edit = (id: string, dto: UserDetails) =>
		ep
			.Put<UserDetails>(id, dto)
			.then(r => {
				useSuccess('Profile saved');
				return r;
			})
			.catch(() => {
				useError('Error saving profile');
				return undefined;
			});

	const Add = () =>
		ep.Post<string>({}).catch(() => {
			useError('Error adding user');
			return undefined;
		});

	return { GetDetails, Edit, Add, IsLoading: ep.IsLoading };
};
