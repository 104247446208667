import React from 'react';
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';

type ButtonAction = 'default' | 'add' | 'edit' | 'view';

type ButtonColor = 'primary' | 'secondary' | 'default';

type ButtonVariant = 'text' | 'outlined' | 'contained';

type ButtonSize = 'small' | 'medium' | 'large';

export interface IEnhancedButtonProps {
	action?: ButtonAction;
	label?: string;
	text?: string;
	color?: ButtonColor;
	submit?: boolean;
	isSubmitting?: boolean;
	icon?: JSX.Element;
	disableIcon?: boolean;
	fullWidth?: boolean;
	margin?: boolean;
	variant?: ButtonVariant;
	size?: ButtonSize;
	disabled?: boolean;
	className?: string;
	isConfigButton?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		buttonMargin: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
	})
);

export const EnhancedButton = (props: IEnhancedButtonProps) => {
	const classes = useStyles();

	let initialText = 'Submit';
	let submitText = 'Submitting';
	let icon = props.icon ? props.icon : undefined;
	if (props.action === 'add') {
		initialText = 'Add';
		submitText = 'Adding';
		icon = <AddIcon />;
	} else if (props.action === 'edit') {
		initialText = 'Save';
		submitText = 'Saving';
		icon = <EditIcon />;
	} else if (props.action === 'view') {
		initialText = 'View';
		submitText = 'Loading';
	}

	if (props.label) {
		initialText += ` ${props.label}`;
		submitText += ` ${props.label}`;
	}

	submitText += '...';

	if (props.disableIcon) {
		icon = undefined;
	}

	const className = props.margin ? `${classes.buttonMargin} ${props.className}` : props.className;
	const buttonText = props.text || (props.isSubmitting ? submitText : initialText);

	return (
		<div className={classes.root}>
			<Button
				type={props.submit ? 'submit' : 'button'}
				color={props.color}
				startIcon={icon}
				fullWidth={props.fullWidth}
				variant={props.variant}
				size={props.size}
				className={className}
				disabled={props.disabled || props.isSubmitting}
				onClick={props.onClick}
			>
				{buttonText}
			</Button>
		</div>
	);
};
