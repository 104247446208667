import React from 'react';

import { AppBar as MuiAppBar, Toolbar, makeStyles, Theme, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LightIcon from '@material-ui/icons/Brightness7';
import DarkIcon from '@material-ui/icons/Brightness4';
import { useAppDispatch, useAppState } from 'Context/AppProvider';

export interface IAppBarProps {
	onAddClick?: () => void;
	onMenuClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: 'none',
		borderBottom: `solid 1px ${theme.palette.divider} `,
		color: theme.palette.text.primary,
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
}));

export const AppBar = (props: IAppBarProps) => {
	const classes = useStyles();
	const state = useAppState();
	const dispatch = useAppDispatch();

	return (
		<MuiAppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<IconButton color="inherit" aria-label="Open drawer" onClick={props.onMenuClick}>
					<MenuIcon />
				</IconButton>
				<div className={classes.grow} />
				{state.Theme === 'light' ? (
					<IconButton
						onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'dark' })}
						color="inherit"
						size="small"
						className={classes.menuButton}
					>
						<DarkIcon />
					</IconButton>
				) : (
					<IconButton
						onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'light' })}
						color="inherit"
						size="small"
						className={classes.menuButton}
					>
						<LightIcon />
					</IconButton>
				)}
			</Toolbar>
		</MuiAppBar>
	);
};
