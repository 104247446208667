import { FormControl, LinearProgress } from '@material-ui/core';
import { EnhancedButton } from 'Common/Elements';
import { NotificationFrequencySelect, TeamSelect } from 'Common/Forms/Fields';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { useTeamsEndpoint, useUsersEndpoint } from 'Endpoints';
import { Form, Formik } from 'formik';
import { Team } from 'Models/TeamModels';
import { UserDetails } from 'Models/UserModels';
import React, { useEffect, useState } from 'react';
import { EditProfileSchema } from './ValidationSchemas/UserSchemas';

export const ProfileForm = () => {
	const ep = useUsersEndpoint();
	const teamEp = useTeamsEndpoint();
	const [teams, setTeams] = useState<Team[]>([]);
	const [user, setUser] = useState(new UserDetails());
	const nameof = useNameof<UserDetails>();

	useEffect(() => {
		ep.GetDetails().then(r => r && setUser(r));
		teamEp.Get().then(r => setTeams(r));
	}, []);

	return (
		<Formik
			initialValues={user}
			enableReinitialize={true}
			validationSchema={EditProfileSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Edit(values.id, values).finally(() => setSubmitting(false));
			}}
		>
			{formprops => (
				<Form>
					<FormControl fullWidth={true}>
						<NotificationFrequencySelect name={nameof('notificationFrequency')} />
					</FormControl>
					<FormControl fullWidth={true}>
						<TeamSelect data={teams} name={nameof('teamId')} />
					</FormControl>
					<FormControl fullWidth={true}>
						{formprops.isSubmitting && <LinearProgress />}
						<EnhancedButton
							submit={true}
							isSubmitting={formprops.isSubmitting}
							action="edit"
							label="Profile"
						/>
					</FormControl>
					{/* {<pre>{JSON.stringify(formprops, null, 2)}</pre>} */}
				</Form>
			)}
		</Formik>
	);
};
