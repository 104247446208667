import { getDateTimeString } from 'Common/Helpers/DateHelper';

export class EntryDetails {
	id = 0;
	description = '';
	occurredOn: Date = new Date();
	meetingName = '';
	agrilifeAgencyId: Nullable<number> = null;
	numberInAudience: Nullable<number> = null;
	grantSponsor = '';
	grantAmount: Nullable<number> = null;
	files: File[] = [];
	entryTypeId = 0;
	entrySubtypeId = 0;
}

export enum EntryTypes {
	AccomplishmentAward = 1,
	Presentation = 2,
	PublicationReport = 3,
	GrantExternalFunding = 4,
	SocialMedia = 5,
	ExternalImpact = 6,
	UpcomingEvent = 7,
	Meeting = 8,
}

export class AddEntry {
	description = '';
	occurredOn: Date = new Date();
	meetingName = '';
	agrilifeAgencyId: Nullable<number>;
	numberInAudience: Nullable<number> = null;
	grantSponsor = '';
	grantAmount: Nullable<number> = null;
	files: File[] = [];
	entryTypeId = 0;
	entrySubtypeId = 0;

	constructor(agrilifeAgencyId: Nullable<number> = null) {
		this.agrilifeAgencyId = agrilifeAgencyId;
	}

	// note: the syntax is important.
	// An arrow function will use prop values when the object was instantiated https://stackoverflow.com/a/56056347
	toFormData() {
		const formData = new FormData();
		formData.append('entrytypeId', this.entryTypeId.toString());
		formData.append('entrysubtypeId', this.entrySubtypeId.toString());
		formData.append('description', this.description);
		formData.append('occurredOn', getDateTimeString(this.occurredOn));
		formData.append('agrilifeAgencyId', (this.agrilifeAgencyId || '').toString());
		formData.append('meetingName', this.meetingName);
		formData.append('numberInAudience', (this.numberInAudience || '').toString());
		formData.append('grantSponsor', this.grantSponsor);
		formData.append('grantAmount', (this.grantAmount || '').toString());
		this.files.map(f => formData.append('files', f, f.name));

		return formData;
	}
}

export class EntryType {
	id = 0;
	name = '';
}

export class EntrySubtype {
	id = 0;
	name = '';
	example = '';
	entryTypeId = 0;
	entryTypeName = '';
	description = '';
	fullName = '';
}

export class Export {
	id = 0;
	entryType = '';
	entrySubtype = '';
	description = '';
	occurredOn: Date = new Date();
	grantSponsor = '';
	grantAmount = 0;
	presentationMeeting = '';
	presentationAudience = 0;
	submittedBy = '';
	team = '';
	agrilifeAgency = '';
	submittedAt: Date = new Date();
	fileExportUrl = '';
	userId = '';
}

export class EditEntry {
	id = 0;
	description = '';
	occurredOn: Date = new Date();
	meetingName = '';
	agrilifeAgencyId: Nullable<number>;
	numberInAudience: Nullable<number> = null;
	grantSponsor = '';
	grantAmount: Nullable<number> = null;
	entryTypeId = 0;
	entrySubtypeId = 0;

	constructor(entryDetails: EntryDetails) {
		this.id = entryDetails.id;
		this.description = entryDetails.description;
		this.occurredOn = entryDetails.occurredOn;
		this.meetingName = entryDetails.meetingName;
		this.agrilifeAgencyId = entryDetails.agrilifeAgencyId;
		this.numberInAudience = entryDetails.numberInAudience;
		this.grantSponsor = entryDetails.grantSponsor;
		this.grantAmount = entryDetails.grantAmount;
		this.entryTypeId = entryDetails.entryTypeId;
		this.entrySubtypeId = entryDetails.entrySubtypeId;
	}
}
