import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { FilterIcon, TableIcons } from 'Common/Tables';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { ArrayHelper } from 'Common/Helpers';
import { Export } from 'Models/EntryModels';
import { getShortDateString } from 'Common/Helpers/DateHelper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EditIcon } from 'Common/Elements';
import { useAppState } from 'Context/AppProvider';

export interface IReportTableProps {
	data: Export[];
	isLoading: boolean;
}

const ReportTable = (props: IReportTableProps & RouteComponentProps) => {
	const nameof = useNameof<Export>();
	const state = useAppState();
	const [isAdvanced, setIsAdvanced] = useState(false);

	return (
		<MaterialTable
			columns={[
				{
					title: 'Entry Type',
					field: nameof('entryType'),
					lookup: ArrayHelper.toObject<Export>(props.data, 'entryType'),
				},
				{
					title: 'Entry Subtype',
					field: nameof('entrySubtype'),
					lookup: ArrayHelper.toObject<Export>(props.data, 'entrySubtype'),
				},
				{ title: 'Description', field: nameof('description') },
				{
					title: 'Grant Sponsor',
					field: nameof('grantSponsor'),
				},
				{ title: 'Grant Amount', field: nameof('grantAmount') },
				{ title: 'Presentation Meeting', field: nameof('presentationMeeting') },
				{ title: 'Presentation Audience', field: nameof('presentationAudience') },
				{
					title: 'Occurred On',
					field: nameof('occurredOn'),
					render: rowData => getShortDateString(rowData.occurredOn),
				},
				{ title: 'Submitted By', field: nameof('submittedBy') },
				{ title: 'Team', field: nameof('team'), lookup: ArrayHelper.toObject<Export>(props.data, 'team') },
				{
					title: 'Agency',
					field: nameof('agrilifeAgency'),
					lookup: ArrayHelper.toObject<Export>(props.data, 'agrilifeAgency'),
				},
				{
					title: 'Submitted On',
					field: nameof('submittedAt'),
				},
			]}
			data={props.data}
			title="Report"
			icons={TableIcons}
			options={{
				pageSize: 10,
				filtering: isAdvanced,
				search: !isAdvanced,
				exportButton: true,
				exportAllData: true,
				actionsColumnIndex: -1,
			}}
			actions={[
				{
					icon: () => <FilterIcon isFiltering={isAdvanced} />,
					tooltip: 'Toggle Advanced Search',
					isFreeAction: true,
					onClick: event => setIsAdvanced(!isAdvanced),
				},
				r => ({
					icon: () => <EditIcon />,
					tooltip: 'Edit',
					hidden: r.userId !== state.CurrentUser?.guid,
					onClick: (event, rowData: Export) => props.history.push('/edit/' + rowData.id),
				}),
			]}
			isLoading={props.isLoading}
		/>
	);
};

export default withRouter(ReportTable);
