/** Makes the values of the provided array's indexKey both the key and value of the resulting object
 * (e.g. [{leadServiceId: 'Army'}, {leadServiceId: 'Navy'}] becomes {Army: 'Army', Navy: 'Navy'})
 */
export function toObject<T>(array: T[] | Readonly<T[]>, indexKey: keyof T) {
	const normalizedObject: any = {};
	for (const each of array) {
		normalizedObject[each[indexKey]] = each[indexKey];
	}
	return normalizedObject as { [key: string]: T };
}
