import React, { useState } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import classnames from 'classnames';

import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
	Theme,
	CssBaseline,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	IconButton,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ReportIcon from '@material-ui/icons/List';
import ProfileIcon from '@material-ui/icons/Person';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';
import { AppBar } from 'Layout';
import * as Pages from 'Pages';
import bgImage from 'Assets/img/gebhartyler-fr-c-Bqx71k-unsplash.jpg';
import settings from 'settings';
import { SignInManager } from 'Common/Auth';

interface ILayoutProps {
	isLoading: boolean;
}

const drawerWidth = 240;

const contentTheme = {
	props: {
		MuiButton: {
			variant: 'contained',
			color: 'primary',
		},
	},
	overrides: {
		MuiPaper: {
			elevation1: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
			},
			elevation2: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.14)',
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				'&$expanded': {
					borderBottom: '1px solid rgba(0, 0, 0, .125)',
				},
			},
		},
		MuiTypography: {
			h5: {
				marginTop: 8,
				marginBottom: 8,
			},
		},
		MuiFab: {
			root: {
				margin: 0,
				top: 'auto',
				right: 20,
				bottom: 20,
				left: 'auto',
				position: 'fixed' as const,
			},
		},
		MuiFormControl: {
			root: {
				margin: 4,
				minWidth: 175,
			},
		},
		MuiTableRow: {
			root: {
				'&:nth-child(even)': {
					backgroundColor: 'rgba(0,0,0,0.03)',
				},
				'&:empty': {
					display: 'none',
				},
			},
		},
		MuiTableCell: {
			body: {
				maxWidth: '300px',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		MuiMenuItem: {
			root: {
				minHeight: 32,
			},
		},
		MuiChip: {
			label: {
				userSelect: 'auto',
			},
		},
	},
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	appBarSpacer: theme.mixins.toolbar,
	toolbarIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	toolbarIcon: {
		color: '#fff',
	},
	bg: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
		backgroundImage: `url('${bgImage}')`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
	},
	bgOverlay: {
		backgroundColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, .6)',
		height: '100%',
	},
	content: {
		padding: theme.spacing(3),
	},
	title: {
		textTransform: 'inherit',
		marginBottom: theme.spacing(2),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	mobileTitle: {
		marginTop: theme.spacing(2),
	},
	pageLinks: {
		textDecoration: 'none',
		color: 'white',
	},
	navItem: {
		margin: '4px',
		borderRadius: '3px',
		width: 'auto',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.23)',
		},
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		zIndex: 1000,
	},
	drawerPaper: {
		color: 'white',
		textTransform: 'uppercase',
		backgroundColor: 'rgba(0, 0, 0, .7)',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		padding: theme.spacing(2),
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
}));

const NavContent = (props: { onItemClick: () => void }) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Typography variant="h5" component="h1" className={`${classes.title} ${classes.mobileTitle}`}>
				{settings.siteName}
			</Typography>
			<Divider />
			<List>
				<Link to="/" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary="Home" />
					</ListItem>
				</Link>
				<Link to="/report" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<ReportIcon />
						</ListItemIcon>
						<ListItemText primary="Report" />
					</ListItem>
				</Link>
				<Link to="/profile" className={classes.pageLinks} onClick={props.onItemClick}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<ProfileIcon />
						</ListItemIcon>
						<ListItemText primary="Profile" />
					</ListItem>
				</Link>
			</List>
		</React.Fragment>
	);
};

export const Layout: React.FunctionComponent<ILayoutProps & RouteComponentProps<any>> = props => {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar onMenuClick={() => setIsDrawerOpen(!isDrawerOpen)} />

			<Drawer
				variant="temporary"
				anchor="left"
				ModalProps={{ onBackdropClick: () => setIsDrawerOpen(false) }}
				classes={{
					paper: classnames('page-links', classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
				}}
				open={isDrawerOpen}
			>
				<div className={classes.toolbarIconContainer}>
					<IconButton onClick={() => setIsDrawerOpen(false)}>
						<ChevronLeftIcon className={classes.toolbarIcon} />
					</IconButton>
				</div>
				<Divider />
				<NavContent onItemClick={() => setIsDrawerOpen(false)} />
			</Drawer>
			<ThemeProvider theme={overallTheme => ({ ...overallTheme, ...contentTheme })}>
				<main className={classes.bg}>
					<div className={classes.bgOverlay}>
						<div className={classes.appBarSpacer} />
						<div className={classes.content}>
							<SignInManager>
								<Switch>
									<Route path="/" exact={true} component={Pages.Home} />
									<Route path="/report" exact={true} component={Pages.Report} />
									<Route path="/edit/:id" component={Pages.Edit} />
									<Route path="/profile" exact={true} component={Pages.Profile} />
								</Switch>
							</SignInManager>
						</div>
					</div>
				</main>
			</ThemeProvider>
		</div>
	);
};

export default withRouter(Layout);
