import React from 'react';
import PencilIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core';

export interface IEditIconProps {
	onClick?: () => void;
	isDisabled?: boolean;
}

const useStyles = makeStyles(theme => ({
	actionIcon: {
		cursor: 'pointer',
		fontSize: '21px',
		marginRight: theme.spacing(1),
	},
	editIcon: {
		color: theme.palette.warning.main,
	},
	editIconNotAllowed: {
		opacity: 0.4,
		cursor: 'not-allowed',
	},
}));

export const EditIcon = (props: IEditIconProps) => {
	const classes = useStyles();
	const editIconClass = props.isDisabled ? classes.editIconNotAllowed : '';

	return (
		<PencilIcon
			className={`${classes.actionIcon} ${classes.editIcon} ${editIconClass}`}
			onClick={() => {
				if (props.isDisabled !== true && props.onClick) {
					props.onClick();
				}
			}}
		/>
	);
};
