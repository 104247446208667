import { Grid } from '@material-ui/core';
import { StyledCard } from 'Common/Cards';
import { EditEntryForm } from 'Forms';
import { setPageTitle } from 'Common/Helpers/DOMHelper';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const Edit = (props: RouteComponentProps<any>) => {
	useEffect(() => {
		setPageTitle('Edit');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} md={4} />
			<Grid item={true} md={5}>
				<StyledCard title="Edit Entry">
					<EditEntryForm id={props.match.params.id} />
				</StyledCard>
			</Grid>
		</Grid>
	);
};

export default withRouter(Edit);
