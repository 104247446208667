import { Grid } from '@material-ui/core';
import { StyledCard } from 'Common/Cards';
import { AddEntryForm } from 'Forms';
import { setPageTitle } from 'Common/Helpers/DOMHelper';
import React, { useEffect } from 'react';

export const Home = () => {
	useEffect(() => {
		setPageTitle('Home');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} md={4} />
			<Grid item={true} md={5}>
				<StyledCard title="Add Entry">
					<AddEntryForm />
				</StyledCard>
			</Grid>
		</Grid>
	);
};
