import { Grid } from '@material-ui/core';
import { StyledCard } from 'Common/Cards';
import { setPageTitle } from 'Common/Helpers/DOMHelper';
import { ProfileForm } from 'Forms';
import React, { useEffect } from 'react';

export const Profile = () => {
	useEffect(() => {
		setPageTitle('Profile');
	}, []);
	return (
		<Grid container={true}>
			<Grid item={true} md={4} />
			<Grid item={true} md={4}>
				<StyledCard title="Profile">
					<ProfileForm />
				</StyledCard>
			</Grid>
		</Grid>
	);
};
