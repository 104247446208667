import { Grid } from '@material-ui/core';
import { setPageTitle } from 'Common/Helpers/DOMHelper';
import React, { useEffect, useState } from 'react';
import { useEntriesEndpoint } from 'Endpoints';
import { Export } from 'Models/EntryModels';
import { ReportTable } from 'Tables';

export const Report = () => {
	const ep = useEntriesEndpoint();
	const [items, setItems] = useState<Export[]>([]);

	useEffect(() => {
		setPageTitle('Report');
		ep.GetExport().then(r => setItems(r));
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} xs={12}>
				<ReportTable data={items} isLoading={ep.IsLoading} />
			</Grid>
		</Grid>
	);
};
