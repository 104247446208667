import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem } from '@material-ui/core';

export interface INotificationFrequencySelectProps {
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the useNameof generic (e.g. useNameof<MyModel>() and nameof('myProp')).
	 */
	name: string;
	/** The label/placeholder for the select element */
	label?: string;
	className?: string;
}

export const NotificationFrequencySelect = (props: INotificationFrequencySelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Notification Frequency'}
			fullWidth={true}
			className={props.className}
		>
			<MenuItem value={0}>Never</MenuItem>
			<MenuItem value={1}>Monthly</MenuItem>
			<MenuItem value={2}>Weekly</MenuItem>
			<MenuItem value={3}>Daily</MenuItem>
		</Field>
	);
};
