import { AddEntry, EditEntry, EntryTypes } from 'Models/EntryModels';
import * as yup from 'yup';
import {
	dateSchema,
	emptyStringSchema,
	nullableNumber,
	numberSchema,
	selectSchema,
	stringSchema,
} from 'Common/Forms/ValidationSchemas/BaseSchemas';

export const AddEntrySchema = yup.object().shape<AddEntry>({
	entryTypeId: selectSchema('Entry Type', 1),
	entrySubtypeId: selectSchema('Entry Type', 1),
	description: stringSchema('Description'),
	occurredOn: dateSchema('Occurred On'),
	meetingName: emptyStringSchema('Meeting Name').when('entryTypeId', {
		is: EntryTypes.Presentation,
		then: stringSchema('Meeting Name'),
	}),
	agrilifeAgencyId: selectSchema('Agency', 1),
	numberInAudience: nullableNumber().when('entryTypeId', {
		is: EntryTypes.Presentation,
		then: numberSchema('Audience Size'),
	}),
	grantSponsor: emptyStringSchema('Grant Sponsor').when('entryTypeId', {
		is: EntryTypes.GrantExternalFunding,
		then: stringSchema('Grant Sponsor'),
	}),
	grantAmount: nullableNumber().when('entryTypeId', {
		is: EntryTypes.GrantExternalFunding,
		then: numberSchema('Grant Amount'),
	}),
	files: yup.array<File>().defined(),
	toFormData: yup.object(),
});

export const EditEntrySchema = yup.object().shape<EditEntry>({
	id: numberSchema('Id', 1),
	entryTypeId: selectSchema('Entry Type', 1),
	entrySubtypeId: selectSchema('Entry Type', 1),
	description: stringSchema('Description'),
	occurredOn: dateSchema('Occurred On'),
	meetingName: emptyStringSchema('Meeting Name').when('entryTypeId', {
		is: EntryTypes.Presentation,
		then: stringSchema('Meeting Name'),
	}),
	agrilifeAgencyId: selectSchema('Agency', 1),
	numberInAudience: nullableNumber().when('entryTypeId', {
		is: EntryTypes.Presentation,
		then: numberSchema('Audience Size'),
	}),
	grantSponsor: emptyStringSchema('Grant Sponsor').when('entryTypeId', {
		is: EntryTypes.GrantExternalFunding,
		then: stringSchema('Grant Sponsor'),
	}),
	grantAmount: nullableNumber().when('entryTypeId', {
		is: EntryTypes.GrantExternalFunding,
		then: numberSchema('Grant Amount'),
	}),
	// toFormData: yup.object(),
});
